<template>
  <div class="activity">
    <container :gutter="0" :sideWidth="0">
      <div>
        <div class="filter">
          <span>活动筛选：</span>
          <el-dropdown>
            <span class="el-dropdown-link">
              全部时间<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>全部时间</el-dropdown-item>
              <el-dropdown-item>本周</el-dropdown-item>
              <el-dropdown-item>本月</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown>
            <span class="el-dropdown-link">
              全部状态<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>全部状态</el-dropdown-item>
              <el-dropdown-item>报名中</el-dropdown-item>
              <el-dropdown-item>活动中</el-dropdown-item>
              <el-dropdown-item>已结束</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div class="activity-list">
          <el-row :gutter="20">
            <el-col :span="12" v-for="i in 10" :key="i">
              <div class="activity-box">
                <div class="poster">
                  <img
                    src="https://dummyimage.com/500x280/ff4777"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="desc">
                  <div class="title">2021 中国投资人未来峰会</div>
                  <div class="desc-text">
                    我们邀请了国内最具代表性的主流投资机构，
                    共同聚焦那些引领行业变革的新资本的新征途，
                    共同探讨中国创投圈的未来走向。
                  </div>
                  <div class="location-time">
                    <div class="tag">报名中</div>
                    <div class="time">
                      <span>
                        <i class="el-icon-time"></i>
                        09月23日-09月24日
                      </span>
                      <span>
                        <i class="el-icon-location"></i>
                        上海
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import Container from "./Container.vue";
export default {
  components: { Container },
};
</script>

<style lang="scss" scoped>
.activity {
  padding: 30px 0;

  .filter {
    margin-bottom: 20px;

    background: #fafafa;
    border-radius: 1px;

    padding-left: 40px;
    height: 60px;

    display: flex;
    align-items: center;

    span:nth-child(1) {
      color: #666;
    }

    ::v-deep .el-dropdown {
      margin-left: 30px;
      span {
        color: #333;
      }
    }
  }

  .activity-list {
    .activity-box {
      user-select: none;
      border-radius: 4px;
      // border: 1px solid #ededed;
      margin-bottom: 40px;

      overflow: hidden;

      transition: all 0.3s;

      &:hover,
      &:active {
        box-shadow: 0 0 20px 1px;
        .poster {
          img {
            transform: scale(1.2);
          }
        }
      }

      .poster {
        height: 280px;
        overflow: hidden;
        img {
          width: 100%;
          transition: all 0.3s;
        }
      }

      .desc {
        width: 100%;
        padding: 14px 20px 16px;
        border: 1px solid #ededed;

        .title {
          width: 100%;
          font-size: 18px;

          line-height: 24px;

          margin-bottom: 10px;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .desc-text {
          color: #999;
          font-size: 14px;
          height: 38px;
          margin-bottom: 13px;

          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          line-clamp: 2;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .location-time {
          border-top: 1px dashed #ddd;

          display: flex;
          justify-content: space-between;
          align-items: center;

          padding-top: 14px;

          .tag {
            padding: 0 5px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            background: #4285f4;
            font-size: 12px;
            color: #fff;
          }

          .time {
            color: #999;
            font-size: 14px;
            span {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
</style>